import React from "react";
import ReactModal from "react-modal";
import styles from "./marketUpdate.module.scss";

import CloseIcon from "@material-ui/icons/Close";

const MarketUpdate = props => {
  return (
 
    <ReactModal 
 
      isOpen={props.open}
      contentLabel="Example Modal In Gatsby"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <CloseIcon
        className={styles.closeButton}
        onClick={() => props.setOpen(false)}
      />
      <iframe
 
        src={props.url}
 
        width="100%"
        height="535"
        type="text/html"
        frameborder="0"
        allowTransparency="true"
      ></iframe>
    </ReactModal>
  );
};

export default MarketUpdate;
